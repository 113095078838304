<template>
  <header :class="{ 'optional-filters-open': customFiltersDropdown }">
    <div class="report-details">
      <h1>{{ dashboardDetails?.name }}</h1>
      <!-- <p class="last-updated">
            Last Updated: Jul 11, 2024 - 9:00 AM
          </p> -->
      <button
        class="button expand-button"
        @click="$emit('toggleContainer')">
        <i class="material-icons">{{
          isContained ? "open_in_full" : "close_fullscreen"
        }}</i>
      </button>
    </div>
    <div class="filters-container">
      <div
        v-if="dashboardDetails?.key"
        class="filters">
        <div
          v-if="dashboardIcon"
          class="logo">
          <img
            :src="dashboardIcon"
            alt="" />
        </div>
        <template v-for="requiredFilter in dashboardDetails.required_filters">
          <div
            v-if="requiredFilter.has_logo"
            :key="requiredFilter.id"
            class="filter">
            <location-filter
              v-model="dataContext"
              :dashboard-key="dashboardDetails.key"
              :filter-key="requiredFilter.key"
              @update-location-logo="updateLocationLogo" />
          </div>
        </template>
        <div class="filter">
          <time-range-filter
            v-model="dataContext"
            :default-time-range="dashboardDetails.default_time_range" />
        </div>
        <div
          v-for="filter in dashboardDetails.dashboard_controls"
          :key="filter.id"
          class="filter">
          <DashboardBenchmarkFilter
            v-if="filter.type === 'BenchmarkSelect'"
            v-model="dataContext"
            :filter-meta="filter" />
          <DashboardControlFilters
            v-else
            v-model="dataContext"
            :filter-meta="filter" />
        </div>
        <div
          v-if="dashboardDetails.optional_filters.length > 0"
          class="filter"
          style="overflow: hidden"
          @click="customFiltersDropdown = !customFiltersDropdown">
          <div
            class="custom-filters-toggle"
            :class="{ 'filtered': isCustomFiltersApplied }">
            <div class="filter-label">
              Filters
            </div>

            <div class="filter-content">
              <div class="custom-filter-label">
                {{ appliedOptionalFiltersLabel || "Custom Filters" }}
              </div>
              <b-icon
                class="icon dropdown-icon"
                :icon="customFiltersDropdown ? 'menu-up' : 'menu-down'" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="customFiltersDropdown"
        class="optional-filters">
        <DashboardOptionalFilters
          v-model="dataContext"
          :optional-filters="dashboardDetails?.optional_filters"
          :dashboard-key="dashboardDetails?.key" />
      </div>
    </div>
    <DashboardTabs
      :value="selectedTab"
      class="dashboard-tabs-section"
      :tabs="tabOptions"
      @input="$emit('updateTab', $event)" />
  </header>
</template>

<script>
// import BenchmarkFilter from './BenchmarkFilter.vue'
  import DashboardControlFilters from './filters/DashboardControlFilters.vue'
  import DashboardBenchmarkFilter from './filters/DashboardBenchmarkFilter.vue'
  import LocationFilter from './filters/LocationFilter.vue'
  import TimeRangeFilter from './filters/DashbaordTimeRangeFilter.vue'
  import DashboardOptionalFilters from './filters/DashboardOptionalFilters.vue'
  import DashboardTabs from '../components/DashboardTabs.vue'
  export default {
    components: {
      TimeRangeFilter,
      DashboardBenchmarkFilter,
      DashboardOptionalFilters,
      LocationFilter,
      DashboardControlFilters,
      DashboardTabs,
    },
    props: {
      isContained: {
        type: Boolean,
        default: true,
      },
      dashboardDetails: {
        type: Object,
        default: () => {},
      },
      value: {
        type: Object,
        default: () => {},
      },
      selectedTab: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        customFiltersDropdown: false,
        selectedLocationLogo: null,
      }
    },

    computed: {
      tabOptions () {
        return (
          this.dashboardDetails?.dashboard_sections?.map((section) => ({
            id: section?.id,
            label: section?.name,
            icon: section?.icon,
          })) || []
        )
      },
      dashboardIcon () {
        const logo =
          this.selectedLocationLogo ||
          this.$store.getters['metadata/companyMetadata']['logo_fpath']
        return `${process.env.VUE_APP_S3_BASE_URL}/${logo}`
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      isCustomFiltersApplied () {
        return Object.keys(this.dataContext.filter_selections).length > 0
      },
      appliedOptionalFiltersLabel () {
        const selectedFilters = Object.keys(this.dataContext.filter_selections)
        if (selectedFilters.length === 0) {
          return 'None'
        }

        return this.dashboardDetails.optional_filters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(', ')
      },
    },

    methods: {
      updateLocationLogo (logo) {
        this.selectedLocationLogo = logo
      },
    },
  }
</script>
<style scoped lang="scss">
.custom-filters-toggle {
  background-color: #fff;
  &:hover {
    box-shadow: none !important;
    border: 1px solid #7e7d84 !important;
    background-color: #fff !important;
  }
}

::v-deep .filter-button {
  border-radius: 6px !important;
  width: 100%;
  cursor: pointer;
  background-color: #fff !important;
  &:hover {
    box-shadow: none !important;
    border: 1px solid #7e7d84 !important;
    background-color: #fff !important;
  }
}

header {
  margin-bottom: 16px;

  .dashboard-tabs-section {
    border-radius: 0 0 6px 6px;
  }

  .report-details {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-bottom: 6px;
    margin-top: 6px;
    .expand-button {
      border: 1px solid #d2d2d4;
      width: 32px;
      height: 32px;
      border-radius: 4px;

      outline: none !important;
      box-shadow: none !important;

      &:hover {
        border: 1px solid $grayscale-4;
        i {
          color: #994095;
        }
      }

      i {
        font-size: 12px;
        color: #b2b1b6;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px; /* 100% */
      letter-spacing: -0.4px;
      margin-right: auto;
      color: $ui-01;
    }

    .last-updated {
      font-size: 14px;
      padding: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      letter-spacing: -0.28px;
      color: hsla(252, 3%, 70%, 1);
    }
  }

  &.optional-filters-open {
    .dashboard-tabs-section {
      border-radius: 6px;
      margin-top: 16px;
    }
    .filters-container {
      border-radius: 6px;
    }
  }
}

.filters-container {
  background-color: #fbfbfb;
  border-radius: 6px 6px 0 0;
}

.filters {
  display: flex;
  padding: 8px;

  align-items: center;

  margin: 0 !important;
  width: 100%;
  gap: 8px;
  .logo {
    border: 1px solid $gray-1;
    background-color: #fff;
    width: 57px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
  }

  .filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    display: relative;
    flex: 1;
  }

  .custom-filters-toggle {
    border: 1px solid $gray-1;
    &.filtered{
      border-color: #994095;
    }
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
    width: 100%;
    padding: 8px 16px;

    .filter-label {
      font-size: 12px;
      color: $grayscale-4;
      width: 100%;
    }

    .filter-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .custom-filter-label {
        color: #504f54;
        font-size: 14px;
        margin-right: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        flex: 1;
      }

      .icon {
        color: #504f54;
      }
    }
  }
}

.right-section {
  width: 100%;
}
</style>
