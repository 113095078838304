<template>
  <div class="dashboard">
    <b-loading
      v-if="isLoading"
      v-model="isLoading"
      :is-full-page="false" />

    <div
      v-else
      class="dashboard-container"
      :class="{
        container: isContained,
      }">
      <MobileDashboardHeader
        v-if="$screen.is('sm')"
        v-model="dataContext"
        :selected-tab="selectedTab"
        :is-contained="isContained"
        class="dashboard-header"
        :dashboard-details="dashboardDetails"
        @updateTab="selectedTab = $event"
        @toggleContainer="toggleContainer" />

      <DashboardHeader
        v-else
        v-model="dataContext"
        :selected-tab="selectedTab"
        :is-contained="isContained"
        class="dashboard-header"
        :dashboard-details="dashboardDetails"
        @updateTab="selectedTab = $event"
        @toggleContainer="toggleContainer" />

      <div class="dashboard-content">
        <div
          v-for="section in dashboardDetails.dashboard_sections"
          :key="section.id">
          <DashboardContent
            v-if="section.id === selectedTab"
            :is-ready="isReady"
            :dashboard-data-context="dataContext"
            :dashboard-key="dashboardKey"
            :dashboard-section="section" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DashboardHeader from './components/DashboardHeader.vue'
  import MobileDashboardHeader from './components/MobileDashboardHeader.vue'

  import DashboardContent from './components/DashboardContent.vue'

  export default {
    components: {
      DashboardHeader,
      MobileDashboardHeader,

      DashboardContent,
    },
    provide () {
      return {
        isContained: () => this.isContained,
      }
    },
    data () {
      return {
        isContained: true,
        isLoading: true,
        selectedTab: '',
        dashboardDetails: null,
        dashboardKey: null,
        dataContext: {
          date_selection: {
            selected_dates: [],
          },
          filter_selections: {},
          control_selections: {},
          query_gen_type: 'BI_API',
        },
      }
    },
    computed: {
      isReady () {
        for (let requiredFilter of this.dashboardDetails?.required_filters) {
          if (!this.dataContext.filter_selections[requiredFilter.key]) {
            return false
          }
        }
        for (let dashboardControl of this.dashboardDetails?.dashboard_controls) {
          if (!this.dataContext.control_selections[dashboardControl.key]) {
            return false
          }
        }
        return true
      },
      variant () {
        return this.$screen.is('sm') ? 'mobile' : 'desktop'
      },
    },
    watch: {
      variant () {
        this.loadDashboard(this.dashboardKey)
      },
    },
    mounted () {
      this.dashboardKey = this.$route.params.id
      let genType = this.$route.query['query_gen_type']
      if (genType) {
        this.dataContext.query_gen_type = genType
      }
      this.loadDashboard(this.dashboardKey)
    },
    methods: {
      toggleContainer () {
        this.isContained = !this.isContained
      },
      async loadDashboard (dashboardKey) {
        this.isLoading = true
        this.dataContext = {
          date_selection: {
            selected_dates: [],
          },
          filter_selections: {},
          control_selections: {},
          query_gen_type: 'BI_API',
        }
        try {
          const dashboardDetails =
            await this.$apis.companyConfigs.getDashboardReportDetails(
              dashboardKey
            )
          this.dashboardDetails = dashboardDetails
          this.selectedTab = this.dashboardDetails?.dashboard_sections[0]?.id
          this.$tracker.capture('dashboard_viewed', {
            dashboard_id: this.dashboardDetails.id,
            dashboard_key: this.dashboardDetails.key,
            dashboard_name: this.dashboardDetails.name,
          })
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 16px;
  background-color: #e9e9ea;

  @include mobile {
    padding: 0px;
  }
}

.dashboard-container {
  padding: 0 !important;
  &.container {
    max-width: 1400px !important;
    margin: 0 auto;
    position: static;
  }
}
.dashboard-header {
  @include mobile {
    margin: 0;
  }
}
</style>
