<template>
  <transition name="fade">
    <div
      v-show="isVisible"
      class="drawer-overlay"
      @click.self="closeDrawer">
      <div
        ref="drawer"
        class="drawer"
        :class="{ 'full-screen': isFullScreen, 'slide-up': isVisible }"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd">
        <div
          ref="dragHandle"
          class="drag-handle"
          @mousedown="handleDragStart">
          <div class="drag-indicator" />
        </div>
        <div class="drawer-header">
          <slot name="header" />
        </div>
        <div class="drawer-content">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Drawer',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },

      isFullScreen: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        isDragging: false,
        startY: 0,
        currentY: 0,
        translateY: 0,
      }
    },
    watch: {
      isVisible (newVal) {
        if (newVal) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      },
    },
    beforeDestroy () {
      document.body.style.overflow = ''
    },
    methods: {
      closeDrawer () {
        // Animate drawer down before closing
        // if (this.$refs.drawer) {
        //   this.$refs.drawer.style.transform = 'translateY(100%)'
        // }
        setTimeout(() => {
          this.$emit('update:isVisible', false)
        }, 200)
      },
      handleTouchStart (e) {
        if (!e.target.closest('.drag-handle')) return
        this.startY = e.touches[0].clientY
        this.isDragging = true
      },
      handleTouchMove (e) {
        if (!this.isDragging) return
        e.preventDefault()

        this.currentY = e.touches[0].clientY
        const deltaY = this.currentY - this.startY

        if (deltaY > 0) {
          this.translateY = deltaY
          this.$refs.drawer.style.transform = `translateY(${this.translateY}px)`
        }
      },
      handleTouchEnd () {
        if (!this.isDragging) return

        if (this.translateY > 150) {
          // Close drawer by continuing downward motion
          this.$refs.drawer.style.transform = 'translateY(100%)'
          setTimeout(() => {
            this.$emit('update:isVisible', false)
          }, 200)
        } else {
          this.resetPosition()
        }

        this.isDragging = false
        this.translateY = 0
      },
      handleDragStart (e) {
        if (!e.target.closest('.drag-handle')) return
        this.startY = e.clientY
        this.isDragging = true
        document.addEventListener('mousemove', this.handleDragMove)
        document.addEventListener('mouseup', this.handleDragEnd)
      },
      handleDragMove (e) {
        if (!this.isDragging) return
        e.preventDefault()

        this.currentY = e.clientY
        const deltaY = this.currentY - this.startY

        if (deltaY > 0) {
          this.translateY = deltaY
          this.$refs.drawer.style.transform = `translateY(${this.translateY}px)`
        }
      },
      handleDragEnd () {
        if (!this.isDragging) return

        if (this.translateY > 150) {
          // Close drawer by continuing downward motion
          this.$refs.drawer.style.transform = 'translateY(100%)'
          setTimeout(() => {
            this.$emit('update:isVisible', false)
          }, 200)
        } else {
          this.resetPosition()
        }

        this.isDragging = false
        this.translateY = 0
        document.removeEventListener('mousemove', this.handleDragMove)
        document.removeEventListener('mouseup', this.handleDragEnd)
      },
      resetPosition () {
        if (this.$refs.drawer) {
          this.$refs.drawer.style.transform = 'translateY(0)'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: flex-end;
}

.drawer {
  background: white;
  width: 100%;
  max-height: calc(100% - 50px);
  border-radius: 16px 16px 0 0;
  // animation: slideUp 0.3s ease-out;
  overflow-y: auto;
  transition: transform 0.2s ease-out;
  display: flex;
  flex-direction: column;
  will-change: transform;
  user-select: none;

  &.full-screen {
    height: 95vh;
  }
}

.drag-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  touch-action: none;
}

.drag-indicator {
  width: 40px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 20px 14px 10px;
  color: $grayscale-3;
  margin-top: 5px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;

  &:hover {
    opacity: 0.7;
  }
}

.drawer-content {
  height: 100%;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes slideUp {
  from {
    transform: translateY(100%) !important;
  }
  to {
    transform: translateY(0) !important;
  }
}
.slide-up {
  transform: translateY(0) !important;
  animation: slideUp 0.3s ease-out;
}
</style>
