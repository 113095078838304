var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metric"},[(!_vm.isLoading)?_c('div',{staticClass:"row main-row"},[_c('div',{staticClass:"left main-title"},[_vm._v(" "+_vm._s(_vm.data.primary_row.label)+" ")]),_c('div',{staticClass:"right main-value-container"},[_c('div',{staticClass:"main-value",class:{
          red: _vm.data.primary_row.conditional_quality === 'bad',
          green: _vm.data.primary_row.conditional_quality === 'good',
          'text-small': _vm.useSmallerFont,
        }},[(_vm.data.primary_row.conditional_direction !== 'none')?_c('i',{staticClass:"material-icons arrow-icon"},[_vm._v(" "+_vm._s(_vm.data.primary_row.conditional_direction === "up" ? "arrow_upward" : "arrow_downward")+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.primary_row?.value)+" ")])])]):_c('b-skeleton',{staticStyle:{"border-radius":"0"},attrs:{"height":"62","width":"100%","active":_vm.isLoading}}),(!_vm.isLoading && _vm.data?.secondary_rows)?_c('div',_vm._l((_vm.data?.secondary_rows),function(subRow){return _c('div',{key:subRow.id,staticClass:"row sub-row"},[_c('div',{staticClass:"left sub-title"},[_vm._v(" "+_vm._s(subRow.label)+" ")]),_c('div',{staticClass:"right sub-value",class:{
          red: subRow.conditional_quality === 'bad',
          green: subRow.conditional_quality === 'good',
        }},[(subRow.conditional_direction !== 'none')?_c('i',{staticClass:"material-icons arrow-icon"},[_vm._v(" "+_vm._s(subRow.conditional_direction === "up" ? "arrow_upward" : "arrow_downward")+" ")]):_vm._e(),_vm._v(" "+_vm._s(subRow.value)+" ")])])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }