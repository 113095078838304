<template>
  <div class="dashboard-content-section">
    <div
      v-if="dashboardSection.controls?.length"
      class="filters">
      <div
        v-for="filter in dashboardSection.controls"
        :key="filter.key"
        class="filter">
        <SectionControlFilters
          v-if="sectionDataContext[filter.key]"
          v-model="sectionDataContext[filter.key]"
          :filter-meta="filter" />
      </div>
    </div>
    <div class="dashboard-sections-container">
      <div
        v-for="section in dashboardSection?.sub_sections"
        :key="section.id"
        class="dashboard-section">
        <h2
          v-if="section.name"
          class="section-name">
          {{ section.name }}
        </h2>

        <div class="columns is-multiline">
          <template v-for="mod in section?.modules">
            <div
              :key="mod?.id"
              :class="[
                'column',
                {
                  'is-full': [
                    'SnapshotTable',
                    'CategoryLineChart',
                    'PerformanceBarChart',
                  ].includes(mod.type),
                  'kpi-module is-one-thirds-tablet is-4-desktop ':
                    mod.type === 'Kpi',
                },
              ]">
              <kpiModule
                v-if="mod.type === 'Kpi'"
                :is-ready="isSectionReady"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" />

              <tableModule
                v-if="mod.type === 'SnapshotTable'"
                :is-ready="isSectionReady"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" />

              <heatmapModule
                v-if="mod.type === 'HeatmapChart'"
                theme="red"
                :is-ready="isSectionReady"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" />

              <graphModule
                v-if="
                  ['CategoryLineChart', 'PerformanceBarChart'].includes(
                    mod.type
                  )
                "
                :is-ready="isSectionReady"
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey"
                :type="mod.type === 'PerformanceBarChart' ? 'bar' : 'line'"
                @onDrilldown="handleDrilldown" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import kpiModule from './modules/kpi/KPIModule.vue'
  import tableModule from './modules/table/TableModule.vue'
  import SectionControlFilters from './filters/SectionControlFilters.vue'
  import graphModule from './modules/graphs/GraphModule.vue'
  import HeatmapModule from './modules/heatmap/HeatmapModule.vue'
  export default {
    components: {
      kpiModule,
      tableModule,
      HeatmapModule,
      SectionControlFilters,
      graphModule,
    },
    props: {
      isReady: {
        type: Boolean,
        required: true,
      },
      dashboardSection: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        sectionDataContext: {},
      }
    },

    computed: {
      mergedDataContext () {
        const newDataContext = { ...this.dashboardDataContext, }
        newDataContext.control_selections = {
          ...this.dashboardDataContext.control_selections,
          ...this.sectionDataContext,
        }
        return newDataContext
      },
      isSectionReady () {
        if (!this.isReady) {
          return false
        }
        for (let control of this.dashboardSection?.controls) {
          if (!this.sectionDataContext[control.key]) {
            return false
          }
        }
        return true
      },
    },

    mounted () {
      this.dashboardSection.controls.forEach((control) => {
        this.$set(this.sectionDataContext, control.key, {
          type: control.type,
          selected_option: null,
          drilldown_path: [],
        })
      })
    },

    methods: {
      handleDrilldown (drilldownData) {
        const control = drilldownData.control
        if (!control) return

        const filterOptionsLength = this.dashboardSection.controls.find(
          (c) => c.key === control
        )?.options?.length
        // Check if drilldown count equals option count
        if (
          filterOptionsLength -
          (this.sectionDataContext[control].drilldown_path?.length || 0) <=
          1
        ) {
          return
        }

        if (!this.sectionDataContext[control].drilldown_path) {
          this.sectionDataContext[control].drilldown_path = []
        }

        this.sectionDataContext[control].drilldown_path.push({
          option: this.sectionDataContext[control].selected_option,
          value: {
            key: drilldownData.key,
            label: drilldownData.label,
          },
        })
      },
    },
  }
</script>
<style scoped lang="scss">
.dashboard-content-section {
  background-color: #fbfbfb;
  border-radius: 6px;
  @include mobile {
    border: none;
    border-radius: 0;
  }
}

.kpi-module {
  min-width: 390px;
  @include mobile {
    min-width: 100%;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  position: sticky;
  border-radius: 4px;
  top: 0px;
  background-color: #f5f5f5;
  z-index: 10;

  @include mobile {
    border-bottom: none;
    padding: 10px;
    gap: 4px;
    position: static;
    z-index: 0;
  }

  .filter {
    flex: 1;
    @include mobile {
      flex-basis: 100%;
      margin-bottom: 10px;
    }
  }
}

.dashboard-sections-container {
  padding: 16px !important;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .section-name {
    padding: 0 4px 16px 4px !important;
    font-size: 16px;
    font-weight: 400;
    color: #7e7d84;
  }
  .dashboard-section {
    // padding: 16px;
    // padding-bottom: 32px;
    &:last-child {
      border-bottom: none;
    }
    @include mobile {
      // border-radius: none;
      border-radius: 0;
      border-bottom: none;
      padding: 0;
      border-left: none;
      border-right: none;
    }
  }

  .columns {
    // gap: 16px;
    @include mobile {
      gap: 0px;
      padding: 0px !important;
    }
  }
}
</style>
