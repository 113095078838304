<template>
  <div id="app">
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :size="'is-small'" />
    <transition name="fade">
      <router-view class="router-view" />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'App',
    components: {},
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
      }),
    },
    mounted () {
      console.log('version:', '1.0.0')
      //todo: log the last deployed time via env variable

    },
  }
</script>

<style lang="scss">
html {
  font-family: "DM Sans", sans-serif;
}

i {
  font-family: Material Icons;
  font-style: normal;
}
#app,
body,
html {
  height: 100%;
}
</style>

<style lang="scss" scoped>
</style>
